import { generateKey, randomize } from "../util/Functions";
import { LocalDb } from "./LocalDb";
import { getGenreData, getPlaylist, query, save } from "./RemoteData";
// import {
//   ALBUM_TABLE_DEF,
//   ARTIST_TABLE_DEF,
//   DASH_DATA_CONFIG,
//   GENRE_TABLE_DEF,
//   MUSIC_TABLE_DEF,
//   PLAYLIST_TABLE_DEF,
//   TABLE_MAP,
//   DB_VERSION,
//   INDEX_NAME,
//   NEWS_TABLE_DEF,
//   PHOTO_TABLE_DEF
// } from "../ObjectConf";
import { organize } from "../util/Functions"; 



const ALBUM_TABLE_DEF = {
  name: 'localDbAlbums',
  key: 'ID',
  fields: ['Name', 'artistFk', 'albumImage', 'image', 'artistName', 'trackCount', 'collectionId']
}
const GENRE_TABLE_DEF = {
  name: 'localDbGenres',
  key: 'ID',
  fields: ['Name', 'Count', 'genreImage', 'percent', 'genreKey']
}
const ARTIST_TABLE_DEF = {
  name: 'localDbArtists',
  key: 'ID',
  fields: ['Name', 'albumCount', 'artistImage', 'image', 'imageLg', 'trackCount']
}
const MUSIC_TABLE_DEF = {
  name: 'localDbMusic',
  key: 'ID',
  fields: ['FileKey', 'FileSize', 'Genre', 'Key', 'Size', 'Title', 'albumArtistFk', 'albumArtistName', 'albumFk', 'albumImage',
    'albumName', 'artist', 'artistFk', 'artistName', 'discNumber', 'explicit', 'genreKey', 'trackId', 'trackNumber', 'trackTime']
}

const PLAYLIST_TABLE_DEF = {
  name: 'localDbPlaylist',
  key: 'ID',
  fields: ['Title', 'image', 'listKey', 'related', 'trackCount']
}

const PHOTO_TABLE_DEF = {
  name: 'photoDb',
  key: 'ID',
  fields: ['avg_color', 'height', 'id', 'liked',
    'photographer', 'photographer_id', 'photographer_url',
    'src', 'url', 'width', 'subject'
  ]
}

const NEWS_TABLE_DEF = {
  name: 'newsDb',
  key: 'ID',
  fields: ['author', 'category', 'description', 'country',
    'source', 'language', 'url', 'published_at', 'title',
    'image', 'subject'
  ]
}

 

const DATA_CONFIG = [
  {
    table: 'localDbMusic',
    type: 'tune',
    text: 'Building music library'
  },
  {
    table: 'localDbAlbums',
    type: 'album',
    text: 'Getting album information'
  },
  {
    table: 'localDbArtists',
    type: 'artist',
    text: 'Getting artist information'
  },
  {
    table: 'localDbGenres',
    type: 'genre',
    text: 'Downloading music categories'
  },
  {
    table: 'localDbPlaylist',
    type: 'playlist',
    text: 'Personalizing your collection'
  },
]


const TABLE_MAP = {
  artist: {
    definition: ARTIST_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'artistFk'
    }
  },
  album: {
    definition: ALBUM_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'albumFk'
    }
  },
  genre: {
    definition: GENRE_TABLE_DEF,
    field: {
      source: 'genreKey',
      destination: 'genreKey'
    }
  },
  tune: {
    definition: MUSIC_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'ID'
    }
  },
  playlist: {
    definition: PLAYLIST_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'ID'
    }
  },
  photo: {
    definition: PHOTO_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'ID'
    }
  },
  news: {
    definition: NEWS_TABLE_DEF,
    field: {
      source: 'ID',
      destination: 'ID'
    }
  }
}

const DASH_DATA_CONFIG = [
  {
    label: 'Top Artists',
    table: 'localDbArtists',
    parse: data => randomize(data.filter(f => f.trackCount > 25)).slice(0, 10)
  },

  {
    label: 'Top Albums',
    table: 'localDbAlbums',
    parse: data => randomize(data.filter(f => f.trackCount > 9)).slice(0, 10)
  },
  {
    label: 'Recently Added',
    table: 'localDbMusic',
    parse: data => randomize(data.filter(f => f.albumImage?.length && !!f.artistFk)).slice(0, 99)
  }
]
const INDEX_NAME = 'amplify_mini_player_ui_db';
const DB_VERSION = 3;


const COOKIE_NAME = 'js-local-api-read-mode';
const READ_MODE = {
  LOCAL: 'LOCAL',
  REMOTE: 'REMOTE'
}
class LocalApi$ {
  Mode = READ_MODE.REMOTE;
  constructor() {
    try {
    console.log([ALBUM_TABLE_DEF, 
      ARTIST_TABLE_DEF, 
      MUSIC_TABLE_DEF, 
      GENRE_TABLE_DEF, 
      PLAYLIST_TABLE_DEF, 
      PHOTO_TABLE_DEF, 
      NEWS_TABLE_DEF])
      LocalDb.init(
        [ALBUM_TABLE_DEF, 
          ARTIST_TABLE_DEF, 
          MUSIC_TABLE_DEF, 
          GENRE_TABLE_DEF, 
          PLAYLIST_TABLE_DEF, 
          PHOTO_TABLE_DEF, 
          NEWS_TABLE_DEF],
        INDEX_NAME,
        DB_VERSION
      );
    } catch (e) {
      console.log({error: e.message})
    }
    !!localStorage[COOKIE_NAME] && (this.Mode = localStorage[COOKIE_NAME]);

  }
  swap() {
    const mode = this.Mode === READ_MODE.REMOTE
      ? READ_MODE.LOCAL
      : READ_MODE.REMOTE
    this.Mode = mode
    localStorage[COOKIE_NAME] = mode;

  }
  query(path) {
    const request = parsePath(path);
    if (request) {
      if (request.type === 'playlist') {
        return getPlaylist(request.id);
      }
      return this.Mode === READ_MODE.REMOTE
        ? query(request.type, request.id)
        : this.getObjectfromLocalDb(request.type, request.id);
    }
    return Promise.reject('Could not parse path!')
  }


  get(type) {
    return query(type);
    // alert([type, this.Mode]);
    // if (this.Mode === READ_MODE.REMOTE) {
    //      return query(type);
    // }
    // const config = TABLE_MAP[type];
    // const indexName = config?.definition?.name;
    // return LocalDb.select(indexName)
  }

  validateIdentifier(type, id) {
    return new Promise(callback => {
      if (type === 'genre' && isNaN(id)) {
        return LocalDb.select(TABLE_MAP.genre.definition.name, row => row.genreKey === id, ['ID'])
          .then(row => callback(row[0].ID));
      }
      callback(id);
    })
  }

  save(track) {
    return LocalDb.update(TABLE_MAP.tune.definition.name, row => row.ID === track.ID, track)
      .then(() => save(track))
  }

  getObjectfromLocalDb(type, id) {
    return this.validateIdentifier(type, id)
      .then(ID => {
        return this.getObjectfromLocalDbAfterValidation(type, ID);
      })
  }

  async count(table) {
    return new Promise(cb => {
      try {
        LocalDb.tally(table || TABLE_MAP.genre.definition.name).then(cb);
      } catch (e) {
        cb(0)
      }
    })
  }

  async select(table, where, columns, order) {
    return LocalDb.select(table, where, columns, order)
  }

  async getPlaylist(id) { 
    // if (this.Mode === READ_MODE.REMOTE) {
      return getPlaylist(id);
    // }
    // const res = await this.get('playlist')
    // const playlist = (res.data || res).filter(d => generateKey(d.Title) === id)[0];
    // if (playlist) {
    //   const Keys = playlist.related.filter(f => !!f);
    //   const response = await this.getTrackListByKeys(Keys)
    //   const related = organize(playlist, response);
    //   return related;
    // }
    // return
  }

  getObjectfromLocalDbAfterValidation(type, id) {
    return new Promise(callback => {
      const config = TABLE_MAP[type];
      const indexName = config?.definition?.name;
      return LocalDb.scalar(indexName, id)
        .then(data => {
          LocalDb.select(TABLE_MAP.tune.definition.name, song => {
            return song[config.field.destination] === data[config.field.source]
          }).then(related => {
            data && (data.related = related);
            callback({ data });
          });
        });
    });
  }

  // application specific methods (MOVE TO API CLASS)
  getTrackListByKeys(Keys) {
    return new Promise(callback => {
      return this.get('tune')
        .then(songs => {
          const out = Keys.map(key => {
            return songs.filter(song => song.Key === key)[0];
          }).filter(f => !!f);
          callback(out);
        });
    })
  }


  getDashData() {
    if (this.Mode === READ_MODE.REMOTE) {
      return query('search/dashSearch');
    }
    const config = DASH_DATA_CONFIG.slice(0);
    return new Promise(callback => {
      const out = [];
      const run = () => {
        if (!config.length) {
          return callback(out)
        }
        const cfg = config.shift();
        return LocalDb.select(cfg.table)
          .then(data => {
            data.map((d, i) => d.ID = d.ID || i)

            out.push(Object.assign(cfg, { result: cfg.parse(data) }));
            return run();
          }).catch(console.log)
      }
      run();
    })
  }

  getGenreData() {
    if (this.Mode === READ_MODE.REMOTE) {
      return getGenreData();
    }
    return new Promise(callback => {
      return LocalDb.select(TABLE_MAP.genre.definition.name)
        .then(items => {
          const genres = randomize(items.filter(genre => {
            return genre.Count > 19 && !!genre.genreImage
          })).slice(0, 2).map(genre => genre.genreKey);
          // callback({ genres })
          const data = []
          LocalDb.select(TABLE_MAP.tune.definition.name)
            .then(tunes => {
              genres.map(genre => {
                data[data.length] = { data: randomize(tunes.filter(f => f.genreKey === genre)).slice(0, 6) }
              })
              callback({ data, genres, items });
            })
        });
    });
  }

}

const LocalApi = new LocalApi$();

export {
  LocalApi,
  READ_MODE
}

const parsePath = path => {
  const regex = /(\w+)\/([^\/]+)/.exec(path);
  if (regex) { 
    return {
      type: regex[1],
      id: regex[2]
    }
  }
}