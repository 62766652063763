import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import HeroMenu from '../HeroMenu/HeroMenu';
import { Collapse, Icon } from '@material-ui/core';
import './SearchBox.css';

const COOKIE_NAME = 'app-search-box-saved-search';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchBox(props) {
  const existing = JSON.parse(localStorage[COOKIE_NAME] || '[]')
  const [saved, setSaved] = useState(existing)
  const [value, setValue] = useState('')
  const classes = useStyles();


  const save = value => {
    const cookie = JSON.parse(localStorage[COOKIE_NAME] || '[]')
      .filter(f => f !== value);
    cookie.unshift(value);
    localStorage[COOKIE_NAME] = JSON.stringify(cookie.slice(0, 10));
    setValue(value)
    load();
  }

  const load = () => {
    setSaved(JSON.parse(localStorage[COOKIE_NAME] || '[]'))
  }

  const find = v => {
    if (v !== undefined) { setValue(v); }
    const address = v.indexOf('://') > -1
      ? v
      : ('https://www.google.com/search?q=' + v)
    window.open(address);
    setFocused(!1);
    save(v);
  }

  

  const handleChange = (arg) => {
    const value = arg?.target?.value;
    setValue(value)
  }
  const [focused, setFocused] = useState()
  return (
    <Paper component="div">
      <div className={classes.root}>
      <IconButton color={focused?'secondary':'default'} onClick={() => setFocused(!focused)} className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        onChange={handleChange}
        value={value}
        onKeyUp={e => e.keyCode === 13 && find(value)}
        placeholder="Search Google"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <HeroMenu {...props} />
      </div>
      <Collapse in={focused} >
      {/* <Divider/> */}
      <div className="SearchBox">
         
        <ul>
          {saved?.map(s => <li onClick={() => find(s)} key={s}><Icon>schedule</Icon>{s}</li>)}
        </ul>
         </div>

      </Collapse>
    </Paper>
  );
}
