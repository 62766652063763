import { Icon, IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import { Subject } from 'rxjs';
import HtmlTooltip from '../HtmlTooltip/HtmlTooltip';
import ListItemToolTip from '../ListItemToolTip/ListItemToolTip';
import './RacingClock.css';


const ticker$ = new Subject();
const ticker = ticker$.asObservable();

const TICK_SPEED = 1000;
const TICK_FACTOR = 1;
const TICK_WIDTH = 24;
const TICK_COUNT = 28;
const SECOND_OFFSET = 7;

const dayOf = i => ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][i];

const racingStripe = () => {
  const cv = document.createElement("canvas");
  cv.width = 570;
  cv.height = 200;
  var ctx = cv.getContext("2d");
  const x1 = cv.width * 0.65;
  const x2 = cv.width * 0.4;
  const y2 = cv.height + 10;
  if (ctx) {
    ctx.lineWidth = 16;
    ctx.strokeStyle = "#ffaaaa";
    ctx.beginPath();
    ctx.moveTo(x1, -10);
    ctx.lineTo(x2, y2);
    ctx.stroke();

    ctx.strokeStyle = "#999999";
    ctx.lineWidth = 8;
    ctx.beginPath();
    ctx.moveTo(x1 + 20, -10);
    ctx.lineTo(x2 + 20, y2);
    ctx.stroke();
  }
  return cv.toDataURL();
}



class RacingClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      am: false,
      dots: [],
      timePoint: {},
      datePoint: {},
      maxLeft: 0,
      pastLeft: {
        emit: i => this.onPastLeft(i)
      },
      url: racingStripe(),
      dropped: false,
      ticking: false
    };
  }


  tick() {
    const run = () => {
      const d = new Date();
      let hh = (d.getHours() % 12)
      const h = d.getHours().toString();
      let m = d.getMinutes().toString();
      const s = d.getSeconds().toString();
      if (parseInt(m, 10) < 10) {
        m = `0${m}`;
      }
      if (hh < 1) hh = 12;
      ticker$.next({ h, m, s, hh });
      window.requestAnimationFrame(run);
    };
    this.setState({ ticking: true });
    window.requestAnimationFrame(run);
  }

  onPastLeft(tick) {
    tick.trans = "left 0s";
    tick.left = (TICK_COUNT - 2) * TICK_WIDTH;
  }


  componentDidMount() {
    const { ticking, pastLeft } = this.state;
    const { factor } = this.props;
    if (ticking) return;

    const dots = []; 
    let actual = Math.max(new Date().getSeconds() - SECOND_OFFSET, 0);
    for (let i = 0; i < TICK_COUNT; i++) {
      const value = actual % 60;
      const second = value < 10 ? `0${value}` : value.toString();
      dots.push(new Tick(pastLeft, second, i * TICK_WIDTH, factor || TICK_FACTOR, i)); 
      actual++;
    }
    this.setState({ dots })
    ticker.subscribe(f => this.display(f))
    this.tick();
    this.go();
  }

  display(f) {
    this.setState({
      timePoint: f,
      am: parseInt(f.h, 10) < 12,
      datePoint: {
        day: dayOf(new Date().getDay()),
        date: new Date().getDate().toString()
      }
    })
  }

  go() {
    const { dots  } = this.state;
    const { speed, shown  } = this.props;
    const seconds = new Date().getSeconds();
    dots.map(d => d.go(seconds));
    setTimeout(() => this.go(), speed || TICK_SPEED);
  }


  render() {
    const { url, datePoint, am, timePoint, dots } = this.state;
    const { selectMusic, alignItems, collapsed, demo, shown } = this.props;
    const storybook = window.location.href.indexOf('localhost') > 0
      ? 'http://localhost:6006/?path=/docs/'
      : 'http://storybook.miltonjones.nl/?path=/docs/';

    return (
      <div className={["RacingClock", alignItems, demo ? 'demo' : ''].join(' ')}>
        <div className="clock" style={{ backgroundImage: 'url(' + url + ')' }}>
          <div className="day">
            <div className="dt-day">{datePoint?.day}</div>
            <div className="dt-date">{datePoint?.date}</div>
          </div>

          <div class="am">
            <div className={am ? '' : 'gray'}>AM</div>
            <div className={am ? 'gray' : ''}>PM</div>
          </div>
          <div class="hh">{timePoint?.hh}</div>
          <div class="colon">:</div>
          <div class="hh">{timePoint?.m}</div>
          <div class={["ss", shown ? ' shown' : ''].join('')} >
            {dots?.map((dot, i) => <Dot key={i} {...dot} />)}
          </div>
          <div class="music" onClick={() => selectMusic && selectMusic()}>
            <HtmlTooltip title={<ListItemToolTip />}>
              <IconButton color={collapsed ? 'primary' : 'secondary'} size="small">
                <Icon>music_note</Icon>
              </IconButton>
            </HtmlTooltip>
          </div>
          <div class="credit">
            ReactJS web components created by <a href="https://www.linkedin.com/in/milton-jones-46b7a211/"
              target="_blank">Milton Jones</a>.
            Like what you see? Check out <a href={storybook} target="_blank">the storybook</a>!
          </div>
        </div>
      </div>
    );
  }
}


const Dot = props => {
  const { left, trans, active, value, ordinal, index } = props;
  const style = {
    left: `${left}px`,
    transition: trans,
    position: 'absolute',
    color: active ? 'black' : '#999',
    // fontWeight: active ? '900' : '400'
  }
  const className = active ? 'active sec' : 'active'
  return <div className={className} style={style}>{value}
    <div className="ss-debug"
      style={{ position: 'absolute', top: -10, fontSize: '.7rem', width: 50 }}
    >{index}</div>
    <div className="ss-debug"
      style={{ position: 'absolute', bottom: -10, fontSize: '.7rem', width: 50 }}
    >{ordinal}</div>
  </div>
}

class Tick {
  left = 0;
  value = "";
  trans = "left 1s";
  active = false;
  pastLeft = null;
  raw = 0;
  index = -1;
  factor = 1;
  previous = 0;
  constructor(tick, v = "", l = 0, f = 1, o = -1) {
    this.pastLeft = tick;
    this.value = v;
    this.left = l;
    this.factor = f;
    this.ordinal = o;
  }
  val(seconds) {
    const time = seconds || new Date().getSeconds();
    this.index = Math.floor(this.left / TICK_WIDTH) - SECOND_OFFSET;
    this.raw = (function (v) { return v > 0 ? v : (60 + v) })(time + this.index);
    const second = Math.max(0, this.raw % 60); 
    return second < 10 ? `0${second}` : second.toString();
  }
  go(seconds) {
    const where = (SECOND_OFFSET + 1) * TICK_WIDTH;
    const min = where - 10;
    const max = where + 10;
    this.trans = "all 1s";
    this.left -= TICK_WIDTH / this.factor;
    this.value = this.val(seconds);
    if (this.left < -TICK_WIDTH ) {
      this.previous = 0;
      this.pastLeft?.emit && this.pastLeft.emit(this);
    }
    this.active = this.left > min && this.left < max;
  }
}

const uniqueList = (objects, field) => {
  const unique = (function (e) { objects.map(o => e[ o[field] ] = o); return e; })({})
  return Object.keys(unique).map(key => unique[key]);
}

RacingClock.defaultProps = {
  alignItems: 'baseline'
};

export default RacingClock;
