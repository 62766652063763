import React, { Component } from 'react';
import { getRemoteNews } from '../../application/services/PhotoService';
import ArticleCard from '../ArticleCard/ArticleCard';
import './NewsScroller.css';

const ARTICLE_WIDTH = 345;
const ARTICLE_GAP = 80;
const ARTICLE_COUNT = 20;

class NewsScroller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      index: 0,
      scrolling: true,
      anim: null,
      subject: 'Amsterdam'
    };
  }

  run(count) {
    const scroller = document.querySelector('.scroll-panel');
    const translateX = `-${(ARTICLE_WIDTH + ARTICLE_GAP) * count}px`;
    const keyframes = [
      { transform: 'translateX(100%)' },
      { transform: `translateX(${translateX})` }
    ];
    this.animate$(scroller, keyframes, Infinity, count * 12000);
  }
  pause(playing) {
    const { anim } = this.state;
    const { onPause } = this.props;
    anim && (playing ? anim.play() : anim.pause());
    onPause && onPause(!playing)
  }
  animate$(element, keyframes, iterations, duration) {
    try {
      let { anim } = this.state;
      if (anim) {
        anim.cancel();
      }
      anim = element.animate(keyframes, {
        duration: duration,
        iterations: iterations
      });
      this.setState({ anim });
    } catch (e) {

    }
  }

  generateKeyFrames(count) {

  }

  mount(sub) {
    const { subject } = this.state;
    getRemoteNews(sub || subject).then(items => {
      const articles = items?.filter(f => !!f.image);
      this.setState({ articles }) ;
      this.run(articles.length);
    });
  }

  componentDidUpdate() {
    const { subject } = this.props;
    if (subject != this.state.subject) {
      this.setState({ subject });
      this.mount(subject)
    }
  }

  componentDidMount() {
    this.mount()
  }

  render() {
    const { articles, scrolling } = this.state;
    const { collapsed } = this.props;
    const mouseEnter = e => {
      this.setState({ scrolling: e })
      this.pause(e)
    }
    const className = scrolling ? 'scroll-panel scrolling' : 'scroll-panel';
    const outerCss = ['NewsScroller'];
    if (scrolling && collapsed) {
      outerCss.push('collapsed')
    }
    return (
      <div className={outerCss.join(' ')}
        onMouseEnter={() => mouseEnter(false)}
        onMouseLeave={() => mouseEnter(true)}
      >
        <div className={className}>
          {articles?.filter(a => !!a.image).map((article, i) => <ArticleCard key={i} mouseEnter={mouseEnter} collapsed={true} article={article} />)}
        </div>
      </div>
    );
  }
}

// const NewsScroller = () => {

// }

NewsScroller.defaultProps = {};

export default NewsScroller;
