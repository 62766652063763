import React, { useState } from 'react';
import HeroBackground from '../HeroBackground/HeroBackground';
import HeroMenu from '../HeroMenu/HeroMenu';
import NewsScroller from '../NewsScroller/NewsScroller';
import PlayerContainer from '../PlayerContainer/PlayerContainer';
import RacingClock from '../RacingClock/RacingClock';
import SearchBox from '../SearchBox/SearchBox';
import TitleBar from '../TitleBar/TitleBar';
import './HeroPage.css';

const HERO_COOKIE_NAME = 'hero-subject';

const HeroPage = () => {
  const startSubject = localStorage.getItem(HERO_COOKIE_NAME) || 'Amsterdam';
  const [alignItems, setAlignItems] = useState('center');
  const [subject, setSubject] = useState(startSubject);
  const [collapsed, setCollapsed] = useState(true);

  const updateSubject = s => {
    localStorage.setItem(HERO_COOKIE_NAME, s)
    console.log({s})
    setSubject(s);
  }

  const onExpanded = e => {
    setAlignItems(e ? 'cornered' : 'center')
  }
  return (
    <div className="HeroPage">
      <TitleBar />
      <HeroBackground subject={subject} />
      <NewsScroller subject={subject} collapsed={alignItems === 'cornered'} onPause={onExpanded} />
      <RacingClock collapsed={collapsed} selectMusic={() => setCollapsed(!collapsed)} alignItems={alignItems} />
      <div className="hero-music-player">
        <PlayerContainer collapsed={collapsed ? '' : 'open'} onExpanded={onExpanded} />
      </div>
      <div className="hero-settings-button">
        {/* <HeroMenu /> */}
        <SearchBox selected={subject} select={updateSubject} />
      </div>
    </div>
  );
}

HeroPage.defaultProps = {};

export default HeroPage;
