import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { parseLine } from '../../application/util/Functions';
import './ArticleCard.css';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ArticleCard({ article, mouseEnter }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(true);

  const handleMouseEnter = (e) => {
    setCollapsed(e);
    mouseEnter && mouseEnter(e);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const parseText = (text, sizes) => {
    const words = text?.split(' ');
    return sizes.map(size => parseLine(words, size));
  } 

  if  (!article?.title) {
    return 'No article has been loaded.'
  }

  const lines = parseText(article?.title, [25, 17]).map(line => <div key={line}>{line}</div>)
  const desc = parseText(article?.description, [25, 30, 30]).map(line => <div key={line}>{line}</div>)
  return (
    <div className="ArticleCard"
      onMouseEnter={() => handleMouseEnter(false)}
      onMouseLeave={() => handleMouseEnter(true)}
    >

      <Card className={classes.root}>


        <Collapse in={!collapsed} timeout="auto" unmountOnExit>

          <CardHeader
            avatar={
              <Avatar aria-label="recipe" src={article?.image} className={classes.avatar}>
                R
          </Avatar>
            }
            title={<div>{lines}</div>}
            subheader={article?.published_at}
          />


        </Collapse>

        <div className="article-card-mini-headline">
          {<div>{lines}</div>}
        </div>

        <CardMedia
          className={classes.media}
          image={article?.image}
          title={article?.author}
        />

        <Collapse in={!collapsed} timeout="auto" unmountOnExit>

          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {desc}
            </Typography>
          </CardContent>

          <CardActions disableSpacing>
            {/* <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton> */}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>



        </Collapse>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {article?.content}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>

    </div>
  );
}
