import React, { Component } from 'react';
import { getRemotePhotos } from '../../application/services/PhotoService';
import { randomize } from '../../application/util/Functions';
import './HeroBackground.css';
const SPEED = 60 * 999;
class HeroBackground extends Component {
  num = -1
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      index: 0,
      subject: 'Amsterdam',
      backgroundSize: 'cover'
    };
  }

  sizeImageToWindow(image) {
    return new Promise(callback => {
      const max = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      const im = new Image();
      im.onload = () => {
        const ratioX = max / im.width;
        const ratioY = height / im.height;
        const h = im.height * ratioX;
        const w = im.width * ratioY;
        const backgroundPosition = '0px 0px';
        const backgroundSize = `${max}px ${height}px`;
        this.setState({ backgroundPosition, backgroundSize })
        callback(image);
      }
      im.onerror = () => callback();
      im.src = image;
    });
  }

  current() {
    let { index, images } = this.state;
    const photo = images[index]
    this.sizeImageToWindow(photo?.src?.large).then(console.log);
  }

  curate(photos) {
    const body = {
      w: document.body.offsetWidth,
      h: document.body.offsetHeight
    }
    const body_horiz = body.w > body.h;
    return randomize(photos.filter(im => {
      const image_horiz = im.width > im.height;
      return image_horiz === body_horiz;
    }))
  }


  next() {
    let { index, images } = this.state;
    index = index < (images.length - 1) ? index + 1 : 0;
    const photo = images[index]
    window.clearTimeout(this.num);
    this.sizeImageToWindow(photo?.src?.large)
      .then(() => {
        this.setState({ index })
        this.num = window.setTimeout(() => this.next(), SPEED)
      });
  }

  componentDidUpdate() {
    const { subject } = this.props;
    if (subject != this.state.subject) {
      this.setState({ subject });
      this.mount(subject)
    }
  }

  mount(sub) {
    const { subject } = this.state;
    getRemotePhotos(sub || subject).then(images => {
      console.log({images})
      this.setState({ images: this.curate(images), index: 0 })
      this.next();
    });
  }

  componentDidMount() {
    this.mount()
    window.addEventListener("resize", () => this.current());
  }

  render() {
    const { images, index, backgroundPosition, backgroundSize } = this.state;
    const photo = images?.[index];
    const image = photo?.src?.large

    return (
      <div style={{ backgroundImage: `url(${image})`, backgroundPosition, backgroundSize }} className="HeroBackground">

      </div>
    )
  }
}
HeroBackground.defaultProps = {
  backgroundSize: 'cover'
};

export default HeroBackground;
