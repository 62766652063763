import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import './Thumbnail.css';
import Caption from '../Caption/Caption';
import { DEFAULT_IMAGE } from '../../application/Constants';

const footers = {

  artist: (obj) => {
    const out = [];
    if (obj.albumCount) {
      out.push(`${obj.albumCount} albums`)
    }
    out.push(`${obj.trackCount} tracks`)
    return out.join(', ')
  },

  album: (obj) => {
    const out = [];
    if (obj.artistName) {
      out.push(`${obj.artistName}`)
    }
    out.push(`${obj.trackCount} tracks`)
    return out.join(', ')
  },
  genre: (obj) => `${obj.Count} tracks`,
  playlist: (obj) => `${obj.trackCount} tracks`,
}


export default function Thumbnail(props) {
  const { editing, select, artist, type, href, exec } = props;
  const history = useHistory();
  let className = ['thumbnail-item', 'Thumbnail'];
  const image = artist?.image || artist?.genreImage || DEFAULT_IMAGE;
  const footer = footers[type]?.(artist);
  const title = artist?.Name || artist?.Title;
  const count = artist.trackCount || artist.Count;
  const address = `/show/${href}/${artist.listKey || artist.ID || artist.genreKey}`;
  const go = (loc) => {
    if (editing) {
      return select(artist);
    }
    exec(artist)
  }
  if (editing) className.push('editing');
  if (artist.selected) className.push('selected');
  className = className.join(' ');
  return <div className={className} onClick={() => go(address)}>
    <Badge max={9999} color="secondary" badgeContent={count}>
      <img className="standard-button" src={image} alt={title} />
    </Badge>
    <Caption text={title} />
    <div className="caption-footer-line">{footer}
    </div>
  </div>;
}

Thumbnail.propTypes = {};

Thumbnail.defaultProps = {
  exec: a => alert(a.Name)
};
