import { Icon, IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import { DEFAULT_IMAGE } from '../../application/Constants';
import { addQueueRequest, playbackRequest, playBegin$, playEnd$ } from '../../application/Events';
import { Analyser } from '../../application/services/AudioAnalyser';
import { LocalApi } from '../../application/services/LocalApi';
import { compareTrackToLists, dataStateChange } from '../../application/services/RemoteData';
import { SongPersistService } from '../../application/services/SongPersistService';
import { AppState } from '../../application/State';
import { playerUrl } from '../../application/util/Functions';
import EqLabel from '../EqLabel/EqLabel';
import ImagePlayButton from '../ImagePlayButton/ImagePlayButton';
import ProgressLabel from '../ProgressLabel/ProgressLabel';
import './PlayerHead.css';

export default class PlayerHead extends Component {
  subscriptions = [];

  constructor(props) {
    super(props);
    this.state = {
      url: '',
      eq_width: 400,
      progress: 0
    };
    this.prevTrack = this.prevTrack.bind(this);
    this.nextTrack = this.nextTrack.bind(this);
    this.close = this.close.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.myInput = React.createRef();
  }
  splice(track) {
    const { index, items } = this.state;
    if (index > -1) {
      items.splice(index + 1, 0, track);
      this.setState({
        ...this.state,
        items,
        before: this.prev(),
        after: this.next()
      });
    }
  }
  next(i = 1) {
    const index = this.state.index + i;
    if (index < this.state.items?.length && index > -1) {
      return this.state.items[index];
    }
  }

  fwd() {
    const track = this.next();
    if (track?.Title) { return track }
  }

  prev() {
    const track = this.next(-1);
    if (track?.Title) { return track }
  }

  attach(audioElement) {
    const { eq_width } = this.props;
    audioElement.addEventListener('ended', () => this.nextTrack());
    audioElement.addEventListener('loadeddata', () => this.loadTrack(audioElement));
    audioElement.addEventListener('timeupdate', () => this.setProgress(audioElement));
    this.setState({
      ...this.state,
      audioElement
    });
    Analyser.attach(audioElement, eq_width);
  }
  setTime() {
    const { audioElement, track } = this.state;
    if (!track.trackTime) {
      const trackTime = audioElement.duration * 1000;
      track.trackTime = trackTime;
      LocalApi.save(track).then(console.log);
      return;
    }

  }
  setArtist(artistFk) {
    if (!artistFk) return;
    // query('artist', artistFk)
    LocalApi.query('artist/' + artistFk)
      .then(res => {
        const { imageLg } = res.data || res;
        this.setState({ ...this.state, imageLg, imageLoaded: !!imageLg })
      })
  }
  loadTrack(e) {
    if (Analyser.context.state !== 'running') {
      const k = window.confirm(`The equalizer needs permission to access your system. 
      Click here to grant permission.`);
      if (k) {
        Analyser.context.resume();
      }
      return;
    }
    const { track, items } = this.state;
    const index = items?.indexOf(track);
    const first = index === 0;
    const last = (index + 1) === items?.length;
    const count = compareTrackToLists(track);
    this.setState({
      ...this.state,
      index,
      imageLoaded: null,
      length: items?.length,
      before: this.prev(),
      after: this.next(),
      first, last, count
    });
    AppState.TRACK = track;
    playBegin$.next(this.state);
    this.setTime();
    this.setArtist(track.artistFk);
    ;
  }

  playTrack(track) {
    if (track) {
      const text = track.FileKey;
      const url = playerUrl(text);
      const index = this.state.items.indexOf(track);
      this.setState({
        ...this.state,
        url,
        index,
        track
      });
      return;
    }
    this.close();
  }

  close() {
    const items = null;
    this.setState({
      ...this.state,
      items
    })
    this.state.audioElement.pause();
    this.props.notify && this.props.notify(false);
    playEnd$.next(this.state);
  }

  prevTrack() {
    const track = this.prev();
    this.playTrack(track)
  }

  nextTrack() {
    const track = this.fwd();
    playEnd$.next(this.state);
    AppState.TRACK = {};
    this.playTrack(track)
  }

  setQueue(opts) {
    const text = opts.track?.FileKey;
    const url = playerUrl(text);
    this.setState({
      ...this.state,
      ...opts,
      url
    });
    this.props.notify && this.props.notify(true);
    SongPersistService.add(opts.track);

  }

  setProgress(player) {
    const duration = player.duration;
    const currentTime = player.currentTime;
    const progress = Math.floor((currentTime / duration) * 100);
    this.setState({
      ...this.state,
      duration, currentTime, progress
    });
  }
  componentDidUpdate() {
    AppState.SOURCE = this.state.source;
  }

  componentWillUnmount() {
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  handleImageClick() {
    if (this.state.audioElement?.paused) {
      this.state.audioElement.play();
      return;
    }
    this.state.audioElement.pause();
  }

  componentDidMount() {
    this.subscriptions.push(
      playbackRequest.subscribe(opts => {
        this.setQueue(opts);
      }),
      addQueueRequest.subscribe(track => {
        this.splice(track);
      }),
      dataStateChange.subscribe(ready => {
        if (ready && this.state.track) {
          const count = compareTrackToLists(this.state.track);
          this.setState({
            ...this.state,
            count
          })
        }
      })
    );
    const audio = document.querySelector('audio');
    // const actual = document.querySelector('.audio-play-head').offsetWidth;
    this.attach(audio);
    // this.setState({ ...this.state, actual })
  }
  render() {
    const {
      first,
      last,
      track,
      before,
      after,
      audioElement,
      progress,
      items,
      count,
      actual,
      crumb,
      imageLg,
      imageLoaded,
      url } = this.state;
    const { setOpened, opened, expanded, mobile } = this.props;
    const datums = [track?.Title, track?.artistName, track?.albumName].filter(f => !!f).join(' - ');
    const image = track?.albumImage || DEFAULT_IMAGE;
    const populated = !!track?.ID;
    const player$ = audioElement;
    const seek = (e) => player$.currentTime = player$.duration * e;
    const css = `audio-player-visible-controls${expanded ? ' expanded' : ''}${imageLoaded ? ' present' : ' retract'}`;
    let eq_width = mobile ? 300 : 480;
    if (expanded) eq_width = actual;
    return (
      <div className={["PlayerHead", populated ? ' open' : ''].join('')}>
        <div className="titlebar">
          Amplify! 2021 Mini-Player. Click any song to begin.
        </div>
        <div className="player-photo control">
          <ImagePlayButton
            click={this.handleImageClick} image={image} alt={datums} paused={audioElement?.paused}
          />
        </div>
        <div className="player-progress control">
          <ProgressLabel seek={seek} width={eq_width} value={progress} state={audioElement?.paused ? 1 : 2} text={datums} />
        </div>
        <div className="player-eq control">
          {!!track?.Key && <EqLabel width={410} />}
        </div>
        <div className="player-bye control">
          <IconButton onClick={this.nextTrack} size="small">
            <Icon>fast_forward</Icon>
          </IconButton>
        </div>
        <div className="player-hider control">
          <IconButton onClick={() => setOpened(!opened)} size="small">
            <Icon>{opened ? 'expand_more' : 'expand_less'}</Icon>
          </IconButton>
        </div>
        <audio id="page-audio-player" autoPlay={true} src={url} crossOrigin="anonymous">
          <source type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    )
  }
}


PlayerHead.defaultProps = {};
