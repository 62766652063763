const ARTIST_API_ADDRESS = 'http://sandbox.miltonjones.nl:1932/';
const CLOUD_FRONT_URL = 'https://s3.amazonaws.com/box.import/';
const DEFAULT_IMAGE = 'http://ullify.com/assets/cdrom-unmount.png';
const APP_NAME = 'MiltonJones.nl'
const SOCKET_HOST = 'http://sandbox.miltonjones.nl:7575';
const DEFAULT_HREF = '#';
const OMITTED_COLUMNS = {
  artist: 'artistName',
  album: 'albumName',
  genre: 'Genre'
}

const MEDIA_STACK_API_KEY = '5cbf3d215bead1adab8053f9f11c9ce9';

const PHOTO_API_KEY =
  "563492ad6f91700001000001b20ca47cb5e84fc4bd9c31aef18f1985";
const NEWS_API_KEY = "ae230f263ba24e9e8106e38970b4c747";

const AUTH_HEADER = {
  headers: {
    'Authorization': PHOTO_API_KEY
  }
}
export {
  ARTIST_API_ADDRESS,
  MEDIA_STACK_API_KEY,
  NEWS_API_KEY,
  CLOUD_FRONT_URL,
  DEFAULT_IMAGE,
  SOCKET_HOST,
  DEFAULT_HREF,
  APP_NAME,
  OMITTED_COLUMNS,
  AUTH_HEADER
}