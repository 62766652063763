import { Icon } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import React from 'react';
import appRoutes from '../../application/Routes';
import HtmlTooltip from '../HtmlTooltip/HtmlTooltip';
import './FooterNavigation.css';

const FooterNavigation = ({ select, type }) => {
  const routes = appRoutes.filter(route => route.data?.type);
  return (
    <div className="FooterNavigation">

      {routes?.map(route => (
        <div key={route.data.type} className="footer-button">
          <HtmlTooltip title={route.data?.label}>
          <IconButton color={type === route.data.type ? 'secondary' : 'primary'} onClick={() => select && select(route.data.type)}>
            <Icon>{route.data?.icon}</Icon>
          </IconButton>
          </HtmlTooltip>
        </div>
      ))}
    </div>
  );
}

FooterNavigation.defaultProps = {};

export default FooterNavigation;
