import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon, IconButton } from '@material-ui/core';
import './HeroMenu.css';

export const PhotoType = {
  NORWAY: "Norway",
  ICELAND: "Iceland",
  TOKYO: "Tokyo",
  BERLIN: "Berlin",
  HOLLAND: "Amsterdam",
  AFRICA: "Africa",
  ALASKA: "Alaska",
  CHINA: "China",
  INDIA: "India",
  PUERTORICO: 'Puerto Rico',
}

export default function HeroMenu({ selected, select, size }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = e => {
    setAnchorEl(null);
    select && select(e)
  };

  return (
    <div>
      <IconButton size={size}
        onClick={handleClick}>
        <Icon classes={{ root: 'hero-menu-button' }}>place</Icon>
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(PhotoType).map(key => <MenuItem
          classes={{ root: (PhotoType[key] === selected ? 'selected-menu-item' : '') }}
          key={key} onClick={() => handleSelect(PhotoType[key])}>{PhotoType[key]}</MenuItem>)}
      </Menu>
    </div>
  );
}

HeroMenu.defaultProps = {
  size: 'small'
}