import React from 'react';
import ToolTipButton from '../ToolTipButton/ToolTipButton';
import './Pagination.css';

function Pagination({ startPage, pageSize, collection, selection, click }) {
  const descText = `${startPage + 1} to ${Math.min(startPage + pageSize, collection.length)} of  ${collection.length}`;
  const last = (startPage + pageSize) >= collection.length;
  const target = collection?.indexOf(selection);
  const diff = Math.floor((target - startPage) / pageSize);

  if (collection.length <= pageSize) return '';
  return (
    <div className="pagination-container flex-centered right">
      {descText}
      <ToolTipButton icon="navigate_before" title="Previous" disabled={startPage < 1} click={() => click(-1)} />
      <ToolTipButton icon="navigate_next" title="Next" disabled={last} click={() => click(1)} />
      <ToolTipButton icon="filter_center_focus" title="center" disabled={diff === 0 || !selection} click={() => click(diff)} />
    </div>
  )
}


Pagination.defaultProps = {};

export default Pagination;
