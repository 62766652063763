import { CLOUD_FRONT_URL } from "../Constants";
import appRoutes from "../Routes";
import { LocalApi } from "../services/LocalApi";


export function generateKey(Title) {
  if (!(Title && Title.replace)) {
    return '';
  }
  return Title.replace(/[.\s-/]/g, '').toLowerCase().trim().replace('the', '');
}


export function playerUrl(FileKey) {
  const audioURL = `${CLOUD_FRONT_URL}${FileKey}`
    .replace('#', '%23').replace(/\+/g, '%2B');
  return audioURL;
}

export function AppleConvert(itunes) {
  return {
    Title: itunes.trackName,
    ID: itunes.trackId,
    trackId: itunes.trackId,
    albumName: itunes.collectionName,
    FileKey: itunes.previewUrl,
    Key: itunes.previewUrl,
    albumImage: itunes.artworkUrl100,
    Genre: itunes.primaryGenreName,
    genreKey: itunes.primaryGenreName,
    discNumber: itunes.discNumber,
    trackTime: itunes.trackTimeMillis,
    trackNumber: itunes.trackNumber,
    artist: itunes.artistName,
    albumArtistName: itunes.artistName,
    artistName: itunes.artistName,

    Size: 0,
    albumFk: 0,
    albumArtistFk: 0,
    artistFk: 0,
    FileSize: 0,
    explicit: false,
  };
}

export function randomize(collection) {
  return collection.map(f => {
    return { f, b: Math.random() * collection.length };
  })
    .sort((a, b) => a.b > b.b ? 1 : -1).map(f => f.f);
}

export function sortObjects(items, type) {
  // 
  const sorter = ListItemSorts[type];
  const field = !sorter ? 'trackNumber' : sorter.replace('^', '');
  const objects = items?.sort((a, b) => sorter?.indexOf('^') > 0 ? (b[field] - a[field]) : (a[field] - b[field]));
  objects.map(obj => obj.id = obj.ID);
  return objects;
}


export function mmss(value, exponent) {
  if (isNaN(value)) return value;
  if (exponent) value = value / exponent;
  const mins = Math.floor(value / 60);
  const secs = Math.floor(value % 60);
  const minutes = mins < 10 ? `0${mins}` : mins;
  const seconds = secs < 10 ? `0${secs}` : secs;
  return `${minutes}:${seconds}`;
}

const organize = (list, tracks) => {
  const output = [];
  list.related?.map((track, i) => {
    const found = tracks.filter(f => stripExt(f.FileKey) === stripExt(track))[0];
    if (found) {
      const exist = output.filter(o => o.FileKey === found.FileKey)[0];
      if (exist) {
        return null;
      }
      found.trackNumber = i + 1;
      output.push(found);
    }
    return found;
  });
  return output;
} 

export async function argsOf(description, summary, detail, type) {
  return await Promise.resolve({
    description,
    table: {
      type: {
        summary,
        detail,
      },
    },
    control: {
      type,
    },
  })
}

export function parseLine(words, limit) {
  let count = 0;
  const tmp = [];
  while (count < limit && !!words?.length) {
    tmp.push(words.shift());
    count = tmp.join(' ').length;
  }
  return tmp.join(' ');
}
export function parseLines(value) {
  const words = value.split(' ');
  return [parseLine(words, 10), parseLine(words, 7)];
}


export const ListItemSorts = {
  artist: 'albumName',
  album: 'trackNumber',
  genre: 'artistName',
  library: 'ID^'
}


export const createCrumb = (path, id, label) => {
  const crumb = appRoutes.filter(route => route.data?.type === path)[0];
  if (crumb) {
    return {
      path,
      id,
      label,
      ...crumb
    }
  }
  return {}
}



const stripExt = (value) => {
  if (!(value && value.replace)) {
    return '';
  }
  const stripped = value.replace(/(\.mp3|\.opus|\.ogg)/g, '');
  if (stripped) {
    return stripped;
  }
  return value;
}
export {
  organize,
  stripExt
}