import { Fab, Icon } from '@material-ui/core';
import React from 'react';
import './ImagePlayButton.css';

const ImagePlayButton = ({ click, image, alt, paused }) => {
  return (
    <div className="ImagePlayButton flex-centered">
      <img src={image} alt={alt}
        className={paused ? '' : 'spinning-cd'} />
      <div className="play-pause-button">
        <Fab size="small" onClick={click} color="primary"><Icon>{paused ? 'play_arrow' : 'pause'}</Icon></Fab>
      </div>
    </div>
  );
}

ImagePlayButton.defaultProps = {
  image: "http://is5.mzstatic.com/image/thumb/Music60/v4/c6/52/89/c65289a7-1eb9-9f76-cfbf-84aa92ff4bd6/source/100x100bb.jpg",
  paused: true,
  click: () => alert('Ouch!'),
  alt: 'image text'
};

export default ImagePlayButton;
