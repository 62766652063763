import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Divider, Icon, ListItemAvatar, ListItemSecondaryAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './ModalTrackList.css';
import Pagination from '../Pagination/Pagination';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { playBegin } from '../../application/Events';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  active: {
    backgroundColor: 'antiquewhite'
  },
  icon: {
    maxWidth: '32px'
  }
}));

export default function ModalTrackList({
  tracks,
  crumb,
  select,
  page,
  menuClick,
  pageSize,
  emit,
  maxWidth,
  maxHeight }) {
  const classes = useStyles();
  const [pageNum, setPageNum] = React.useState(1);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const startPage = ((pageNum - 1) * pageSize);
  const listItems = tracks?.slice(startPage, startPage + pageSize);
  const selectedTrack = tracks.filter(t => t.ID === selectionModel?.[0])[0]

  const handleNext = (index) => {
    setPageNum(pageNum + index)
  }
  let sub = null;
  useEffect(() => {
    if (sub) return;
    sub = playBegin.subscribe(i => {
      setSelectionModel([i.track?.ID])
    })
  }, [sub])
  return (
    <div>

      {!!crumb && <Breadcrumb emit={emit} crumb={crumb} />}

      <div style={{ outline: 'solid 1px #777', maxHeight, maxWidth, overflow: 'auto' }} className={classes.root}>

        <List dense component="nav" aria-label="main mailbox folders" >
          {listItems?.map((track, i) => {
            const { ID, Title, albumImage, artistName, albumName, trackNumber } = track;
            const selected = selectionModel?.indexOf(ID) > -1;
            let className = [page ? classes.root : 'standard-button', 'no-wrap'];
            if (selected) className.push(classes.active);
            else if (i % 2 === 0) className.push('row-even')
            className = className.join(' ');
            return (
              <ListItem
                style={{ margin: 0, padding: 0 }}
                onClick={() => select(track)}
                key={ID}
                classes={{
                  gutters: classes.gutter,
                  multiline: classes.multiline,
                  root: className
                }} >
                <ListItemAvatar style={{ minWidth: '52px', padding: '6px' }}>
                  <Avatar alt={Title} src={albumImage} />
                </ListItemAvatar>
                <ListItemText
                  classes={{ primary: classes.label, secondary: classes.label }}
                  key={ID}
                  secondary={(
                    <div>
                      <div className="no-wrap">
                        {artistName}
                      </div>
                      <div className="no-wrap">
                        {albumName}
                      </div>
                    </div>
                  )}
                  primary={(trackNumber || '#') + '. ' + Title} />
                {menuClick && <ListItemSecondaryAction onClick={() => menuClick(track)}>
                  <Icon>more_vert</Icon>
                </ListItemSecondaryAction>}
              </ListItem>
            )
          })}
        </List>

        <Pagination startPage={startPage} selection={selectedTrack} pageSize={pageSize} collection={tracks} click={handleNext} />

      </div>
    </div>
  );
}

ModalTrackList.propTypes = {};

ModalTrackList.defaultProps = {
  pageSize: 5,
  select: i => alert('SELECT WAS CLICKED'),
  selectionModel: [],
  menuClick: i => alert('menuClick WAS CLICKED'),
  maxHeight: 400,
  maxWidth: 500
};
