import React from 'react';
import { parseLines } from '../../application/util/Functions';
import './Caption.css';

const Caption = (props) => {
  const lines = parseLines(props.text);
  return (
    <div className="caption-title-lines">
      {lines.map((line, k) => <div key={k} className="caption-title-line">{line}</div>)}
    </div>
  );
}

Caption.propTypes = {};

Caption.defaultProps = {};

export default Caption;
