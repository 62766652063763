import React, { useEffect } from 'react';
import { APP_NAME } from '../../application/Constants';
import './TitleBar.css';

const TitleBar = () => {
  useEffect(() => document.title = APP_NAME);
  return ''
}

TitleBar.defaultProps = {};

export default TitleBar;
