
import { useEffect } from 'react';
import './App.css';
import HeroPage from './components/HeroPage/HeroPage';

function App() {
  return (
    <div className="App">
      <HeroPage />
    </div>
  );
}


export default App;
