import { Icon, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import SortMenu from '../SortMenu/SortMenu';
import './GridViewToolbar.css';

const GridViewToolbar = (props) => {
  const { editing, setEditing, emit, open, selectedCount, sortUpdate, groupItems, sorter, crumb } = props;
  return (
    <div className="GridViewToolbar">
      <div className="upper-menu-left">
        <Breadcrumb emit={emit} open={open} crumb={crumb} />
      </div>
      <div className="upper-menu-right">
        {sorter?.length && <SortMenu update={sortUpdate} items={sorter} />}
      </div>
    </div>
  );
}

GridViewToolbar.defaultProps = {
  selectedCount: 0,
  sortUpdate: () => alert('sort update'),
  groupItems: () => alert('group items'),
  crumb: {}
};

export default GridViewToolbar;
