import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { Icon, useMediaQuery } from '@material-ui/core';
import {
  BrowserRouter as Router,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  root: {
    margin: '4px 20px'
  },
  open: {
    margin: '4px 20px 4px 256px'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  noicon: {
    margin: 0,
  },
}));



const Breadcrumb = (props) => {
  const classes = useStyles();
  const { crumb, open, emit } = props;
  const matches = !0; //useMediaQuery('(max-width:600px)');

  return (
    <Router>

      <Breadcrumbs aria-label="breadcrumb" classes={{ root: open && !matches ? classes.open : classes.root }}>
        {
          !crumb.label && (<Link color="inherit" onClick={() => emit && emit(crumb.data?.type)} className={classes.link}>
            <Icon>chevron_left</Icon>
            {!matches && 'Home'}
          </Link>)
        }


        {
          crumb?.data?.label && (<Link
            color="inherit"
            onClick={() => emit && emit(crumb.data?.type)}
            className={classes.link}
          >
            <Icon className={classes.noicon}>{crumb.data.icon}</Icon>
            {!matches && crumb.data.label}
          </Link>)
        }


        {
          crumb?.label && (<Typography color="textPrimary" className={classes.link}>

            {truncate(crumb.label, matches)}
          </Typography>)
        }
      </Breadcrumbs>
    </Router>
  );
}


const truncate = (str, yes) => {
  if (yes && str.length > 15) {
    return str.substr(0, 15) + '...';
  }
  return str;
}


export default Breadcrumb;
