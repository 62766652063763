
import axios from 'axios';
import { AUTH_HEADER, NEWS_API_KEY, MEDIA_STACK_API_KEY } from '../Constants'
import { TABLE_MAP } from '../ObjectConf';
import { LocalApi } from './LocalApi';
import { LocalDb } from './LocalDb';
import backup from '../../news-backup-data.json';
const now = () => ['getFullYear', 'getMonth', 'getDate'].map(f => new Date()[f]().toString().padStart(2, 0)).join('-');
const mediaOf = subject => `http://api.mediastack.com/v1/news?access_key=${MEDIA_STACK_API_KEY}&keywords=${subject}&limit=100&date=${now()}`
const newsOf = subject => `http://newsapi.org/v2/everything?q=${subject}&from=${now()}&sortBy=publishedAt&apiKey=${NEWS_API_KEY}`;
const photosOf = subject => `https://api.pexels.com/v1/search?query=${subject}`
const nameOf = name => TABLE_MAP[name].definition.name;

async function getRemoteNews(subject) {
  const table = nameOf('news')
  const rows = await LocalDb.select(table, row => row.subject === subject)
   if (rows.length) return rows;
  return new Promise(callback => {
    axios.get(mediaOf(subject))
      .then(res => {
        const { data } = res;
        const articles = data.data; 
        articles.map(p => p.subject = subject);
        LocalDb.insert(table, articles)
          .then(msg => {
            console.log({ msg })
            callback(articles);
          });
      })
      .catch(e => {
        callback(backup.articles);
      })
  })
}

async function getRemotePhotos(subject) {
  const table = nameOf('photo')
  const rows = await LocalApi.select(table, row => row.subject === subject);
  if (rows.length) return rows;
  const { data } = await axios.get(photosOf(subject), AUTH_HEADER);
  const { photos } = data;
  photos.map(p => p.subject = subject);
  const msg = await LocalDb.insert(table, photos);
  console.log({ msg })
  return photos;
}


export {
  getRemotePhotos,
  getRemoteNews
}
// this.http.get(this.apiUrl, { headers: new HttpHeaders().set("Authorization", PHOTO_API_KEY)}