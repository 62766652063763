import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { AppState } from '../../application/State';
import { createCrumb, generateKey } from '../../application/util/Functions';
import { ThumbViewSorters } from '../../application/util/ThumbViewSorters';
import GridViewToolbar from '../GridViewToolbar/GridViewToolbar';
import Thumbnail from '../Thumbnail/Thumbnail';
import './ThumbnailGridView.css';

const ThumbnailGridView = (props) => {
  const [editing, setEditing] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedCount, setSelectedCount] = useState(0)
  const [collection, setCollection] = useState([])
  const { open, items, cacheType, href, emit, pageSize } = props;
  const className = ['ThumbnailGridView', 'thumbnail-view'];
  if (open) className.push('open');
  if (AppState.PLAYING) className.push('collapsed');
  const sorter = ThumbViewSorters[cacheType];
  const crumb = createCrumb(cacheType);


  // const selectedCount = items?.filter(i => i.selected).length;

  const sortUpdate = e => {
    console.log({ e })
  }
  const groupItems = e => {
    console.log({ e })
  }

  const fetchMoreData = () => {
    goto(page + 1);
  }

  const execItem = i => { 
    props.play && props.play(i)
  }

  const selectItem = i => {
    i.selected = !i.selected;
    setSelectedCount(items?.filter(i => i.selected).length);
    // alert(selectedCount)
  }

  const goto = page => {
    setPage(page)
    items.map(f => f.Title && (f.listKey = generateKey(f.Title)));
    setCollection(items.slice(0, page * pageSize))
  }

  useEffect(() => {
    if (items.length && !collection.length) {
      goto(1)
    }
  })

  return (
    <div className="thumb-grid-view-container">
      <GridViewToolbar
        editing={editing}
        setEditing={setEditing}
        open={open}
        emit={emit}
        selectedCount={selectedCount}
        sortUpdate={sortUpdate}
        groupItems={groupItems}
        sorter={sorter}
        crumb={crumb}
      />
      <div id="thumbnail-view" className={className.join(' ')}>
        <InfiniteScroll
          dataLength={collection?.length}
          next={fetchMoreData}
          scrollableTarget="thumbnail-view"
          hasMore
        >
          {collection?.map((artist, k) => (
            <Thumbnail
              key={k + href}
              select={a => selectItem(a)}
              editing={editing}
              href={href}
              exec={execItem}
              type={cacheType}
              artist={artist} />))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

ThumbnailGridView.defaultProps = {
  items: [],
  href: '',
  // fetchMoreData: a => alert('SCROLL BOTTOM'),
  pageSize: 40
};

export default ThumbnailGridView;
