import { Avatar, Icon, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React from 'react';
import './ListItemToolTip.css';

const ListItemToolTip = () => {
  return (
    <div className="ListItemToolTip">
      <List dense style={{ width: '400px', padding: '6px' }}>
        <ListItem>
          <ListItemAvatar style={{ minWidth: '52px', padding: '6px' }}>
            <Avatar><Icon>music_note</Icon></Avatar>
          </ListItemAvatar>
          <ListItemText primary="Play Music"
            secondary="Open the Amplify! mini-player" />
        </ListItem>
      </List>
    </div>
  );
}

ListItemToolTip.defaultProps = {};

export default ListItemToolTip;
