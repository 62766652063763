import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayerHead from '../PlayerHead/PlayerHead';
import './PlayerContainer.css';
import { Divider, Icon } from '@material-ui/core';
import { LocalApi } from '../../application/services/LocalApi';
import FooterNavigation from '../FooterNavigation/FooterNavigation';
import ThumbnailGridView from '../ThumbnailGridView/ThumbnailGridView';
import ModalTrackList from '../ModalTrackList/ModalTrackList';
import { sendRequestToPlayer } from '../../application/services/PlayerRequest';
import { createCrumb, generateKey, sortObjects } from '../../application/util/Functions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 545,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PlayerContainer({ detail, onExpanded, collapsed }) {
  const classes = useStyles();
  const [opened, setOpened] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [args, setArgs] = React.useState({});

  const handleSetExpanded = e => {
    setExpanded(e);
    onExpanded && onExpanded(expanded || opened)
  }

  const handleSetOpened = e => {
    setOpened(!opened);
    onExpanded && onExpanded(e)
  }

  const handleExpandClick = () => {
    handleSetExpanded(!expanded);
  };

  const sendPlayRequest = (track, objects, type, id) => {
    const crumb = createCrumb(type, id, '')
    const items = objects;
    const index = items.indexOf(track);
    const source = `${type}/${id}`;
    sendRequestToPlayer({ items, track, index, source, crumb });
  }

  const onSelect = (type, id, label) => {
    LocalApi.query(`${type}/${id}`).then(res => {
      const { related } = res.data || res;
      console.log({ res, related })
      const objects = related || res.data || res;
      const tracks = sortObjects(objects, type);
      const params = {
        tracks,
        type,
        select: i => {
          sendPlayRequest(i, tracks, type, id)
        },
        page: 1,
        selectionModel: [],
        menuClick: console.log,
        pageSize: 5,
        maxHeight: 440,
        emit: i => onNavigate(i),
        crumb: createCrumb(type, id, label)
      }
      setArgs(params);
    })
  };

  const onNavigate = type => {
    setArgs({});
    LocalApi.get(type).then(res => {
      setArgs({
        type,
        cacheType: type,
        items: res.data || res,
        emit: i => onNavigate(i),
        play: i => { 
          onSelect(type,
            i.Title
              ? generateKey(i.Title)
              : i.ID || i.genreKey, i.Name || i.Titlte)
        }
      })
      handleSetExpanded(!0)
      console.log(res)
    })
  }


  return (
    <Card className={[classes.root, 'player-container', collapsed].join(' ')}>

      <CardContent>
        <PlayerHead setOpened={handleSetOpened} opened={opened} />
      </CardContent>

      <Divider />


      <Collapse in={expanded && opened} timeout="auto" unmountOnExit>

        <CardContent>
          <div className="navigation-content">
            {!!args?.tracks && <ModalTrackList {...args} />}
            {!!args?.cacheType && <ThumbnailGridView {...args} />}
          </div>
        </CardContent>


      </Collapse>

      {expanded && opened && <Divider />}

      <Collapse in={detail && !expanded} timeout="auto" unmountOnExit>


      <CardContent>
        The media player appears in the upper left corner of the <a href="/?path=/story/hero-heropage--default-view">Hero Page</a> <Icon>open_in_new</Icon> interface when the 
        user clicks the <Icon>music_note</Icon> icon on the Racing Clock. Browse music categories 
        by clicking any of the icons below. <Icon>arrow_downward</Icon>
        </CardContent>



      </Collapse>

      <Collapse in={opened} timeout="auto" unmountOnExit>


        <CardActions style={{ justifyContent: 'center' }} disableSpacing>
          <FooterNavigation {...args} select={onNavigate} />
        </CardActions>



      </Collapse>


    </Card>
  );
}

PlayerContainer.defaultProps = {
  pageSize: 15
}